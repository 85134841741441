<template>
  <div>
    <load-profile></load-profile>
    <main-tab />

    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', resource)">
            <b-button variant="success" @click="createSchedule">
              {{ $t("button.newSchedule") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :deletable="false"
          :viewable="true"
          :resource="resource"
          :route="route"
          primary-field="number"
        >
          <template #cell(createdBy)="data">
            <span v-if="data.item.createdBy">
              {{ data.item.createdBy.name }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(bidDate)="data">
            {{ data.item.bidDate | formatDate("DD-MMMM-YYYY") }}
          </template>
          <template #cell(startDate)="data">
            {{ data.item.startDate | formatDate("LT") }}
          </template>
          <template #cell(endDate)="data">
            {{ data.item.endDate | formatDate("LT") }}
          </template>
          <template #cell(showDate)="data">
            {{ data.item.showDate | formatDate }}
          </template>
          <template #cell(status)="data">
            <span
              :class="{
                'text-warning': data.item.status == 0,
                'text-primary': data.item.status == 1 || data.item.status == 2,
                'text-success': data.item.status == 3,
                'text-danger': data.item.status == 4,
              }"
            >
              {{ $t(`schedule.${data.item.status}`) }}
            </span>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                :disabled="
                  !$can('update', resource) ||
                  data.item.status != scheduleState.RUNNING
                "
                v-b-tooltip.hover
                :title="$t('button.update')"
                :variant="
                  !$can('update', resource) ||
                  data.item.status != scheduleState.RUNNING
                    ? 'secondary'
                    : 'primary'
                "
                pill
                size="sm"
                @click="updateSchedule(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                :to="{
                  name: `view-${route}`,
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.vehicleList')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="ListIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$t('button.remark')"
                variant="primary"
                pill
                size="sm"
                @click="openRemark(data.item)"
              >
                <feather-icon icon="MessageCircleIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>

    <update-schedule-modal @success="getData" ref="updateScheuldeModal" />
    <create-schedule-modal @success="getData" ref="createScheduleModal" />
    <remark-modal ref="remarkModal" />
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import UpdateScheduleModal from "./updateSchedule/UpdateModal.vue";
import CreateScheduleModal from "./createSchedule/CreateModal.vue";
import MainTab from "./MainTab.vue";
import RemarkModal from "./auctionRemark/RemarkModal";

const AuctionScheduleRepository = Repository.get("auctionSchedule");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    MainTab,
    UpdateScheduleModal,
    CreateScheduleModal,
    RemarkModal,
  },
  watch: {
    perPage() {
      this.list();
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        bidDate: this.$route.query.bidDate || [],
        status: this.$route.query.status || [],
        locationId: this.$route.query.locationId || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        bidDate: this.$route.query.bidDate || [],
        status: this.$route.query.status || [],
        locationId: this.$route.query.locationId || [],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    createSchedule() {
      this.$refs.createScheduleModal.show();
    },
    updateSchedule(item) {
      this.$refs.updateScheuldeModal.show(item);
    },
    openRemark(item) {
      this.$refs.remarkModal.show(item);
    },
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    list(page = 1) {
      this.query.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: this.searchFields,
      };
      AuctionScheduleRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "auction-schedule";
    const route = "auction-schedule";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
